<template>
  <div class="">
    <!--  -->
    <div v-if="isPlanLoading">
      <PricingCardSkeletonVue  />
    </div>
    <div v-else>
      <div class="text-left ChoosePlanNew">
        <div class="row Choose-plan-Main">
          <div class="col-12">
            <div class="main-title">
              Plans that fit your scale
            </div>
            <div class="main-description my-2">Clear and flexible pricing designed to grow with your business.
            </div>
          </div>
          <div v-if="user.planId == 1 || user.planId == 10" class="text-center text-danger text center flex-grow-1"  style="font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;">
      You are currently using the free plan.
    </div>
          <div class="col-12 d-flex flex-column justify-content-center my-5">
            <!-- New Buttons -->
            <div class="pricing-switcher mb-2">
        <div class="fieldset">
            <input v-model="selectedBtn" type="radio" name="group-a" value="yearly" id="yearly">
            <label for="yearly" class="m-0">Yearly (Save 12%)</label>
            <input v-model="selectedBtn" type="radio" name="group-a" value="monthly" id="monthly">
            <label for="monthly" class="m-0">Monthly</label>
         <span class="switch"></span>

        </div>
    </div>
    <!--  -->
    
    </div>

          <div class="col-12 ">
            <!-- Yearly Plan start-->
            <div class="row " v-if="selectedBtn == 'yearly'">
              <div
                class="col-12 col-sm-10 mx-auto mx-xl-0 col-md-12 col-lg-4 mb-3 h-100"
                v-for="(plan, index) in yearlyPlans"
                :key="index"
              >
                <PricingCard
                  :plan="plan"
                  :mode="'yearly'"
                  :user="user"
                  @openPlanFunc="openPlan($event)"
                />
              </div>
            </div>
            <!-- Yearly Plan end-->

            <!-- Monthly Plan starts -->
            <div class="row" v-else>
              <div
                class="col-12 col-sm-10 mx-auto mx-xl-0 col-md-12 col-lg-4  mb-3 h-100"
                v-for="(plan, index) in withoutFreePlan"
                :key="index"
                style="height: 100%"
              >
                <PricingCard
                  :plan="plan"
                  :mode="'monthly'"
                  :user="user"
                  @openPlanFunc="openPlan($event)"
                />
              </div>
            </div>
            <!-- Monthly Plan ends -->
          </div>

<div class="col-12 mt-3">
  <EnterpriseCard/>
</div>

          <div class="col-12 mt-3">

            <PriceCompareTable @openPlanFunc="openPlan($event)" :user="user" :mode="selectedBtn" :price="selectedBtn == 'yearly'?yearlyPlans:monthlyPlans" />
          </div>
          <div class="col-12 mt-3">
            <FAQ/>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PricingCardSkeletonVue from "../product/Skeletons/PricingCardSkeleton.vue";
import PriceCompareTable from "./components/PriceCompareTable.vue"
import axios from "axios";
import PricingCard from "./PricingCard.vue";
import { mapGetters } from "vuex";
import EnterpriseCard from "./components/EnterpriseCard.vue"
import FAQ from './components/FAQ.vue';
// const Paddle = window.Paddle;
export default {
  components: {  EnterpriseCard, FAQ, PricingCardSkeletonVue, PricingCard ,PriceCompareTable },
  data() {
    return {
      selectedBtn: "yearly",
      planFeature: [
        {
          text: "Integrations",
        },
        {
          text: "Jump Logics",
        },
      ],
      

      monthlyPlans: [],
      yearlyPlans: [],
      user: {},
      isPlanLoading: false,
    };
  },
  computed: {
    withoutFreePlan(){
      return this.monthlyPlans.filter(plan => plan.name !== 'Free')
    },
    freePlan(){
      return this.monthlyPlans.find(plan => plan.name === 'Free');
    },
    ...mapGetters(["GetTokenFromMetaTag", "getQuizID", "getNavbarData"]),

    getUserType() {
      return this.getNavbarData.userType;
    },
  },
  methods: {
  
    openSupport() {
      let url = "https://support.quizell.com/";
      window.open(url, "_blank");
    },
    validatePlan(plan, planType) {
      // user.planId
      // let isCurrentPlan = false;
      let planTypeMode = "";

      let currentPlanId = this.user.planId;

      if (currentPlanId == 7 || currentPlanId == 8 || currentPlanId == 9) {
        planTypeMode = "yearPlan";
      }
      if (currentPlanId == 4 || currentPlanId == 5 || currentPlanId == 6) {
        planTypeMode = "monthlyPlan";
      }
      if (currentPlanId == 1) {
        planTypeMode = "freePlan";
      }
      let isValid;
      // if(currentPlanId == plan.id){
      //   isCurrentPlan = true;
      // }
      if (planTypeMode == "freePlan") {
        isValid = true;
      } else {
        if (planType == "yearly") {
          if (planTypeMode == "monthlyPlan") {
            isValid = true;
          } else {
            if (currentPlanId <= plan.id) {
              isValid = true;
            } else {
              isValid = false;
            }
          }
        } else {
          if (planTypeMode == "yearPlan") {
            isValid = true;
          } else {
            if (currentPlanId <= plan.id) {
              isValid = true;
            } else {
              isValid = false;
            }
          }
        }
      }

      // console.log("Mode",planTypeMode)
      // console.log("Value",plan.id,isValid,planType)
      return isValid;
    },
    async getUser() {
      const response = await axios.get(`/getCurrentUser`);

      if (response.status == 200 && response.data.status == "fail") {
        this.user = {};
      } else {
        this.user = response.data.data;
      }
    },
    changeSelectedBtn(item) {
      this.selectedBtn = item;
    },

    getTextColor(plan) {
      let style = ` color:${plan.color};`;
      return style;
    },
    async getPlans() {
      try {
        this.isPlanLoading = true;
        const response = await axios.get(`/loadPricePlans`);
        if (response.status == 200 && response.data.status == "success") {
          if (
            response.data.data.plans.monthly &&
            response.data.data.plans.monthly.length
          ) {
            this.monthlyPlans = response.data.data.plans.monthly;
          }

          this.yearlyPlans = response.data.data.plans?.yearly;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isPlanLoading = false;
      }
    },
    openPlan(plan) {
      if (plan.paddle_plan_id && plan.id !== this.user.planId) {
        let self = this;

        window.Paddle.Checkout.open({
          vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID),
          product: plan.paddle_plan_id,
          email: this.user.email,
          customData: { email: this.user.email },
          quantity: 1,
          allowQuantity: false,
          successCallback: function (data) {
           data;
            // self.$refs.PaymentSuccessRefComp.OpenModal("success");
            self.$router.push(`/payment/successfull`)
          },
          closeCallback: function () {
            self.$refs.PaymentSuccessRefComp.OpenModal("close");
          },
          passthrough: {},
        });
      } else {
        this.$toasted.show("Payment is not applicable for selected plan", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    getBtnStyles(plan) {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:${plan.bg_color};color:#ffffff;opacity:0.8;`;

      return style;
    },
    getDisabledBtnStyles() {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:#7C8290;color:#ffffff;opacity:0.8;pointer-events:none !important;`;

      return style;
    },
    isBtnDisabled(plan) {
      if (Object.entries(this.user).length !== 0) {
        if (plan.id == this.user.plan_id) return true;
        return false;
      } else {
        return false;
      }
    },
  },
//  aysnc created(){
//     this.getUser();
//     this.getPlans();
//   },
  mounted(){
    if (typeof window.Paddle === 'undefined') {
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.async = true; 

  
    script.onreadystatechange = script.onload = () => {
      if (!script.readyState || /loaded|complete/.test(script.readyState)) {
        if (window.Paddle) {
          window.Paddle.Setup({ vendor: 157567 });
        } else {
          console.error('Paddle script loaded, but Paddle is not available.');
        }
      }
    };

    document.head.appendChild(script);
  } else {
    window.Paddle.Setup({ vendor: 157567 });
  }

  this.getUser();
    this.getPlans();
  }
};
</script>
<style scoped>
.main-description{
  color: #7C8290;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align:center
}
.main-title{
  font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: -0.02em;
    text-align: center;
}
   .pricing-switcher {
    text-align: center;
    line-height: 0;
}

.fieldset {
    display: inline-block;
    position: relative;
    padding: 2px;
    border-radius: 100px;
    border: 1px solid #eaeaea;
    background: #fff;
    box-shadow: 0 4px 16px rgba(77, 27, 126, 0.1);
}

.pricing-switcher label {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 170px;
    height: 50px;
    line-height: 50px;
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
    transition: color 0.3s ease;
}

.pricing-switcher input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.pricing-switcher input[type="radio"]:checked + label {
    color: var(--primary-color);  /* Text color for checked state */
}

.pricing-switcher input[type="radio"] + label {
    color: #101828;  /* Default text color */
    border-color: var(--white) ;
}

.switch {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 50px;
    width: 170px;
    background: #F3E7FF;  /* Default background color */
    border-radius: 50em;
    transition: transform 0.3s;
}

.pricing-switcher input[type="radio"]:nth-of-type(1):checked ~ .switch {
    transform: translateX(0px);  /* For "Yearly" */
    background: #F3E7FF!important;; /* Background color when "Yearly" is selected */
}

.pricing-switcher input[type="radio"]:nth-of-type(2):checked ~ .switch {
    transform: translateX(170px);  /* For "Monthly" */
    background: #F3E7FF!important;; /* Background color when "Monthly" is selected */
}

.ChoosePlanNew {
  height: 100%;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}
.ChoosePlanNew .MainSection {
  padding: 1% 5%;
}

.Choose-plan-Main {
  padding: 2% 1.5%;
}

/* .BtnGroupOuter{
  padding: 2px;
          border-radius: 58px;
          background: linear-gradient(90deg, #FFC000 0.05%, #6B3EDA 47.93%, #4DABF8 99.96%);
} */
 .BtnGroupOuter .btn-group {
  background: #ffffff;
  border-radius: 100px;
  color: #4d1b7e;
}
.BtnGroupOuter .btn-group p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  text-align: center;

  color: #000;
}

.BtnGroupOuter .btn-group .selectedBtn {
  background: #F3E7FF;

  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #4D1B7E;
  border-radius: 100px;
  padding: 10px 14px;
}
.PBtnGroupOuter .btn-group .selectedBtn:hover {
  color: #ffffff;
}
.Choose-plan-Main .topSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 136%;
  text-align: center;
  color: #1f1f28;
}


</style>
